import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Baseurl from '../Baseurl.js';
import axios from 'axios'; // Import Axios

function ChangePassword() {
  // State variables for current password, new password, and confirmation password
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null); // To store error messages
  const [successMessage, setSuccessMessage] = useState(null); // To store success messages

  // Handler for form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Simple validation
    if (!currentPassword || !newPassword || !confirmPassword) {
      alert('All fields are required.');
      return;
    }

    if (newPassword !== confirmPassword) {
      alert('New password and confirmation do not match.');
      return;
    }

    try {
      // Retrieve userId from local storage
      const userId = localStorage.getItem('userId');

      // Send API request to change password using Axios
      const response = await axios.post(`${Baseurl}change-password.php`, {
        user_id: userId, // Include userId in the request body
        current_password: currentPassword,
        new_password: newPassword,
      });

      // Log the complete response
      console.log('API Response:', response);

      // Handle success
      setSuccessMessage(response.data.message || 'Password changed successfully!');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setError(null); // Clear any previous errors
    } catch (err) {
      // Handle error
      if (err.response) {
        // Server responded with a status other than 2xx
        setError(err.response.data.message || 'An error occurred while changing the password.');
      } else {
        // Network error or other issues
        setError('Network error. Please try again later.');
      }
      setSuccessMessage(null); // Clear any previous success messages
    }
  };

  return (
    <div>
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">Change Password</div>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <FontAwesomeIcon icon={faLock} className="input-icon" />
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Current Password"
            className="login-input"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faLock} className="input-icon" />
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="New Password"
            className="login-input"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faLock} className="input-icon" />
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Confirm New Password"
            className="login-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        <div className="form-check input-container text-start mt-1 px-5">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)}
          />
          <label className="form-check-label text-danger" htmlFor="flexCheckDefault">
            Show Password
          </label>
        </div>

        {error && <div className="text-danger">{error}</div>}
        {successMessage && <div className="text-success">{successMessage}</div>}

        <div>
          <button type="submit" className="btnn">Change Password</button>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
