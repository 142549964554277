import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../Component/img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear all local storage data
    localStorage.clear();

    // Redirect to home page or login page
    navigate('/');
  }, [navigate]);

  return (
    <div className="logout-page">
      <FontAwesomeIcon icon={faLock} />
      <h2>Logging out...</h2>
    </div>
  );
}

export default Logout;
