import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 
import axios from 'axios'; 
import Baseurl from '../Baseurl.js';
import { Link } from "react-router-dom";

function HowToPlay() {
  const [howToPlayText, setHowToPlayText] = useState("Loading...");  

  useEffect(() => {
    // Function to fetch the "How To Play" text from the API
    const fetchHowToPlayText = async () => {
      try {
        const response = await axios.post(`${Baseurl}/fetch-website-detail.php`);
        
        // Access the how_to_play_text from the first item in the data array
        if (response.data.data && response.data.data.length > 0) {
          setHowToPlayText(response.data.data[0].how_to_play_text); // Update state with the fetched text
        } else {
          setHowToPlayText("No How To Play text available."); // Fallback if no data is returned
        }
      } catch (error) {
        console.error("Error fetching How To Play text:", error);
        setHowToPlayText("Error fetching How To Play text."); // Fallback for errors
      }
    };

    fetchHowToPlayText();
  }, []); 

  return (
    <div>
      <div className="bgred">
        <div className="d-flex align-items-center px-2 pt-2">
        <Link to="/teer" className="noudl">
            <div className="me-2">
              <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
            </div>
          </Link>
          <div className="bbhg me-2">How To Play</div>
        </div>
      </div>

      <div className="m-2 bd2">
        <div className="bldblk">How To Play</div>
        <div>{howToPlayText}</div> {/* Display the fetched "How To Play" text */}
      </div>
    </div>
  );
}

export default HowToPlay;
