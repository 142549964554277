import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios"; // Import Axios
import Baseurl from "../Baseurl.js";
import { Link } from 'react-router-dom';


import ProfileComp from "../Component/ProfileComp";
import AddressComp from "../Component/AddressComp";

function Profile() {
  
 
   

  return (
    <div className="vh-100 bgred44">
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
        <Link to="/teer" className="noudl"> 
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          </Link>
          <div className="bbhg me-2">Profile</div>
        </div>
      </div>

      
      <ProfileComp />
      <AddressComp />
      
    </div>
  );
}

export default Profile;
