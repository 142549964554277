import React, { useState, useEffect } from 'react';
import logo from '../Component/img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons'; 

function Login() {
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    // Set a timeout to hide the logo after 2 seconds
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, 3000);

    // Clean up the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>

{showLogo && (
          <div className="bg-redd2">
            <div className="mt-3">
              <img src={logo} className="img-fluid ft1" alt="Logo" />
            </div>
          </div>
        )}

      <div className="bgred"></div>
      <div className="mt-3">
        <img src={logo} className="img-fluid ft1" alt="Logo" />
      </div>

      <div className="input-container">
        <FontAwesomeIcon icon={faLock} className="input-icon" />
        <input type="text" placeholder="Enter MPIN Number" className="login-input" />
      </div>

      <div>
        <button className="btnn">Login</button>
      </div>
       <div className="fg1 text-end mx-3 mt-4">FORGET MPIN?</div>
      <div>
        <button className="btnn">Logout</button>
      </div>
      
      
    </div>
  );
}

export default Login;
