import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 
import Baseurl from '../Baseurl.js';

function GameHistory() {
  const [gameHistory, setGameHistory] = useState([]); // Initialize as empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const userId = localStorage.getItem('userid');
  
  useEffect(() => {
    const fetchGameHistory = async () => {
      try {
        // Make API call to fetch game history
        const response = await axios.post(`${Baseurl}/game-history.php`, {
          user_id: userId
        });

        console.log("API Response:", response);
        console.log("Response Data:", response.data);
        console.log("Game History Data:", response.data.data);

        // Set the game history data if available
        if (response.data && response.data.data) {
          setGameHistory(response.data.data);
        } else {
          setGameHistory([]);
        }
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching game history:", error); // Log error to the console
        setError("Error fetching game history");
        setLoading(false);
      }
    };

    // Call the fetchGameHistory function when the component mounts
    if (userId) {
      fetchGameHistory();
    } else {
      setError("User ID not found");
      setLoading(false);
    }
  }, [userId]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="vh-100">
      <div className="bgred">
        <div className="d-flex align-items-center px-2 pt-2">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          <div className="bbhg me-2">Game History</div>
        </div>
      </div>

      <div className="m-2">
        <div className="row p-0 m-0">
          <div className="col p-0 m-0">
            <div className="btnn5">10/09/2024</div>
          </div>
          <div className="col p-0 m-0">
            <div className="btnn5">10/09/2024</div>
          </div>
          <div className="col p-0 m-0">
            <button className="btnn">Submit</button>
          </div>
        </div>
      </div>

      {/* Display Game History Data */}
      <div className="px-3 loapp">
        {gameHistory.map((game, index) => (
          <div key={game.id} className="mb-3 ">
          <div  className="njamh11">
          {game.game_name}
 
          </div>


<div className="card p-2">
<div className="d-flex justify-content-around">
              <div className="kasooo">
                <span>Play On:</span><br />
                <span>{formatDate(game.created_at)}</span>
              </div> 
              <div className="kasooo">
                <span>Play For:</span><br />
                <span>{formatDate(game.created_at)}</span>
              </div> 
            </div>

            <div className="d-flex justify-content-around">
              <div className="kasoo13o">
                <span>Bid Id:</span><br />
                <span>{game.id}</span>
              </div> 
              <div className="kasoo13o">
                <span>Digits:</span><br />
                <span>{game.input_key}</span>
              </div> 
              <div className="kasoo13o">
                <span>Points:</span><br />
                <span>{game.input_value}</span>
              </div>
            </div>

            <div className="text-center">
               <div className="kasoo13o">Bid Time : 11:00 AM</div>
               <div className="lab78">Reasult not announced</div>
            </div>




</div>
          

          
          </div>
        ))}
      </div>
    </div>
  );
}

export default GameHistory;
