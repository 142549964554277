import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify'; // Import Toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import Baseurl from "../Baseurl.js"; // Adjust the path as necessary

function AddressComp() {
  // State variables for address details
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [error, setError] = useState(null); // To store error messages
  const [loading, setLoading] = useState(true); // Loading state

  const userId = localStorage.getItem("userid"); // Get user ID from local storage
  console.log("User ID:", userId); // Log the user ID on component load

  // Fetch existing address on component mount
  useEffect(() => {
    const fetchAddress = async () => {
      setLoading(true); // Set loading state
      if (!userId) {
        setLoading(false);
        return; // Exit if no user ID
      }

      try {
        const response = await axios.get(`${Baseurl}get-address.php`, {
          params: { user_id: userId },
        });

        console.log("Fetched address response:", response.data); // Log the response data

        if (response.data.data) {
          setAddress(response.data.data.address);
          setCity(response.data.data.city);
          setPincode(response.data.data.pincode);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError('Error fetching address data.');
        console.error("Fetch error:", err); // Log the fetch error
      } finally {
        setLoading(false); // Reset loading state
      }
    };

    fetchAddress();
  }, [userId]);

  // Handler for adding address
  const handleAddressAdd = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (!userId) {
      setError('User ID not found.');
      return;
    }

    // Validate input
    if (!address || !city || !pincode) {
      setError('All fields in the address are required.');
      return;
    }

    // Prepare the data to send
    const addressData = {
      user_id: userId,
      address,
      city,
      pincode,
    };

    // Log the data being sent
    console.log("Sending address data:", addressData);

    try {
      // Send API request to add address using Axios
      const response = await axios.post(`${Baseurl}add-address.php`, addressData);

      // Log the API response
      console.log("API response:", response.data);

      // Handle success
      toast.success(response.data.message || 'Address added successfully!'); // Use Toast for success notification
      setError(null); // Clear any previous errors
      // Clear form fields after successful submission
      // Remove these lines to retain the input values
      // setAddress('');
      // setCity('');
      // setPincode('');
    } catch (err) {
      // Handle error
      if (err.response) {
        setError(err.response.data.message || 'An error occurred while adding the address.');
        console.error("Error response:", err.response.data); // Log error response
      } else {
        setError('Network error. Please try again later.');
      }
      // Show error toast message
      toast.error(err.response?.data.message || 'An error occurred. Please try again.'); // Show error message with Toast
    }
  };

  return (
    <div className="m-2 bg-white bdrs">
      <ToastContainer position="top-center" /> {/* Set position to top-center */}
      {loading && <div>Loading...</div>} {/* Loading indicator */}
      {!loading && (
        <form onSubmit={handleAddressAdd}>
          <div className="row m-0 p-0 g-3">
            <div className="col">
              <div className="py-2 px-3">
                <div className="ty3 lo">Address</div>

                <div className="input-container2">
                  <div className="text-start">
                    <label className="lbl12">Address</label>
                  </div>
                  <input
                    type="text"
                    placeholder="Address"
                    className="login-input2"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>

                <div className="input-container2">
                  <div className="text-start">
                    <label className="lbl12">City</label>
                  </div>
                  <input
                    type="text"
                    placeholder="City"
                    className="login-input2"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>

                <div className="input-container2">
                  <div className="text-start">
                    <label className="lbl12">Pincode</label>
                  </div>
                  <input
                    type="text"
                    placeholder="Pincode"
                    className="login-input2"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <button type="submit" className="btnn mb-3">Add Address</button>
          </div>
        </form>
      )}
      {error && <div className="text-danger">{error}</div>} {/* Show error message */}
    </div>
  );
}

export default AddressComp;
