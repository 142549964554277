import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import Baseurl from "../Baseurl.js";
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast
import { Link } from 'react-router-dom';

function AddBankAccount() {
  const [accountNumber, setAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');

  const userId = localStorage.getItem("userid"); // Get user ID from local storage

  // Function to fetch bank details for the user
  const fetchBankDetails = async () => {
    try {
      const response = await axios.get(`${Baseurl}get-bank-details.php?id=${userId}`);
      const { account_number, bank_name, ifsc_code, account_holder_name } = response.data;

      // Set state with fetched data
      setAccountNumber(account_number || '');
      setBankName(bank_name || '');
      setIfscCode(ifsc_code || '');
      setAccountHolderName(account_holder_name || '');
    } catch (error) {
      console.error("Error fetching bank details:", error.response?.data || error.message);
    }
  };

  useEffect(() => {
    // Fetch bank details on component mount
    fetchBankDetails();
  }, []); // Empty dependency array means this effect runs once after the initial render

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Trim the input values to remove any leading or trailing whitespace
    const trimmedAccountNumber = accountNumber.trim();
    const trimmedBankName = bankName.trim();
    const trimmedIfscCode = ifscCode.trim();
    const trimmedAccountHolderName = accountHolderName.trim();

    // Log the data being sent
    console.log("Data being sent to API:", {
      id: userId,
      account_number: trimmedAccountNumber,
      bank_name: trimmedBankName,
      ifsc_code: trimmedIfscCode,
      account_holder_name: trimmedAccountHolderName,
    });

    // Check if any fields are empty
    if (!trimmedAccountNumber || !trimmedBankName || !trimmedIfscCode || !trimmedAccountHolderName) {
      console.error("All fields are required.");
      return; // Prevent the API call
    }

    try {
      // Send a POST request with the data in the request body
      const response = await axios.post(`${Baseurl}add-bank-details.php`, {
        id: userId,
        account_number: trimmedAccountNumber,
        bank_name: trimmedBankName,
        ifsc_code: trimmedIfscCode,
        account_holder_name: trimmedAccountHolderName,
      });

      // Log the response received
      console.log("Response from API:", response.data);

      // Show success message
      toast.success("Bank details added successfully!"); // Display success toast
    } catch (error) {
      console.error("Error adding bank details:", error.response?.data || error.message); // Handle error response
      toast.error("Error adding bank details."); // Display error toast
    }
  };

  return (
    <div className="vh-100">
      <ToastContainer /> {/* Include ToastContainer here */}
      <div className="bgred">
        <div className="d-flex align-items-center px-2 pt-2">
        <Link to="/teer" className="noudl">
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div></Link>
          <div className="bbhg me-2">Add Bank Account</div>
        </div>
      </div>

      <div className="m-2 bg-white bdrs">
        <form onSubmit={handleSubmit}> {/* Use a form to handle submission */}
          <div className="row m-0 p-0 g-3">
            <div className="col">
              <div className="py-0 px-3">
                <div className="ty3 lo">Bank Details</div>

                <div className="input-container2">
                  <div className="text-start">
                    <label className="lbl12">Account Number</label>
                  </div>
                  <input
                    type="text"
                    placeholder="Account Number"
                    className="login-input2"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)} // Update state on input change
                    required
                  />
                </div>

                <div className="input-container2">
                  <div className="text-start">
                    <label className="lbl12">Bank Name</label>
                  </div>
                  <input
                    type="text"
                    placeholder="Bank Name"
                    className="login-input2"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)} // Update state on input change
                    required
                  />
                </div>

                <div className="input-container2">
                  <div className="text-start">
                    <label className="lbl12">IFSC Code</label>
                  </div>
                  <input
                    type="text"
                    placeholder="IFSC Code"
                    className="login-input2"
                    value={ifscCode}
                    onChange={(e) => setIfscCode(e.target.value)} // Update state on input change
                    required
                  />
                </div>

                <div className="input-container2">
                  <div className="text-start">
                    <label className="lbl12">Account Holder's Name</label>
                  </div>
                  <input
                    type="text"
                    placeholder="Account Holder's Name"
                    className="login-input2"
                    value={accountHolderName}
                    onChange={(e) => setAccountHolderName(e.target.value)} // Update state on input change
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <button type="submit" className="btnn mb-3">Add Bank Details</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddBankAccount;
