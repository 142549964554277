import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import Baseurl from "../Baseurl.js";

function PointHistory() {
  const [pointHistory, setPointHistory] = useState([]); // State to hold the point history
  const [filteredHistory, setFilteredHistory] = useState([]); // State to hold filtered records
  const [startDate, setStartDate] = useState(""); // State for start date
  const [endDate, setEndDate] = useState(""); // State for end date
  const userId = localStorage.getItem("userid"); // Get user ID from localStorage

  useEffect(() => {
    const fetchPointHistory = async () => {
      try {
        const response = await axios.post(`${Baseurl}/fetch-all-funds.php`, {
          user_id: userId,
        });
        const sortedData = response.data.data.sort((a, b) => new Date(b.regdate) - new Date(a.regdate)); // Sort in descending order
        setPointHistory(sortedData); // Set the point history from the response
        setFilteredHistory(sortedData); // Initialize filtered history
      } catch (error) {
        console.error("Error fetching point history:", error);
      }
    };

    fetchPointHistory(); // Call the function to fetch data
  }, [userId]); // Run effect when userId changes

  const handleFilter = () => {
    const filtered = pointHistory.filter(record => {
      const regDate = new Date(record.regdate);
      return regDate >= new Date(startDate) && regDate <= new Date(endDate);
    });

    // Sort the filtered results in descending order
    const sortedFiltered = filtered.sort((a, b) => new Date(b.regdate) - new Date(a.regdate));
    setFilteredHistory(sortedFiltered); // Update the filtered history
  };

  return (
    <div className="vh-100">
      <div className="bgred">
        <div className="d-flex align-items-center px-2 pt-2">
          <Link to="/teer" className="noudl">
            <div className="me-2">
              <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
            </div>
          </Link>
          <div className="bbhg me-2">Point History</div>
        </div>
      </div>

      <div className="m-2">
        <div className="row p-0 m-0">
          <div className="col p-0 m-0">
            <input
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="col p-0 m-0">
            <input
              type="date"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="col p-0 m-0">
            <button className="btnn" onClick={handleFilter}>
              Submit
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col py-3">
            <table className="table table-bordered mb-1 mt-1">
              <thead>
                <tr>
                  <th className="py-3 backrg1 lok111">Date</th>
                  <th className="py-3 backrg1">Amount</th>
                  <th className="py-3 backrg1 lok112">Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredHistory.length > 0 ? (
                  filteredHistory.map((record, index) => (
                    <tr key={index}>
                      <td className="text-start py-2 pop3">
                        {new Date(record.regdate).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </td>
                      <td className="text-center py-2 pop3">&#8377;{record.amount}</td>
                      <td className="text-center py-2 pop3">
                        {record.add_status}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No point history available for the selected dates.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PointHistory;
