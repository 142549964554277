import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Baseurl from "../Baseurl.js";
import what from "../Component/img/what.svg";
import Telegram from "../Component/img/Telegram.webp";
import { Link } from "react-router-dom";

function HelpSupport() {
  const [contactInfo, setContactInfo] = useState({
    email: "Loading...",
    address: "Loading...",
    phone: "Loading...",
    whatsappLink: "",
    telegramLink: "",
  });

  useEffect(() => {
    // Function to fetch the contact information from the API
    const fetchContactInfo = async () => {
      try {
        const response = await axios.post(`${Baseurl}/fetch-website-detail.php`);

        // Access the first item in the data array and set contact information
        if (response.data.data && response.data.data.length > 0) {
          const data = response.data.data[0];
          setContactInfo({
            email: data.email || "No email available",
            address: data.address || "No address available",
            phone: data.phone || "No phone number available",
            whatsappLink: data.whatsapp_link || "#",
            telegramLink: data.telegram_link || "#",
          });
        } else {
          setContactInfo({
            email: "No email available.",
            address: "No address available.",
            phone: "No phone number available.",
            whatsappLink: "#",
            telegramLink: "#",
          });
        }
      } catch (error) {
        console.error("Error fetching contact information:", error);
        setContactInfo({
          email: "Error fetching email.",
          address: "Error fetching address.",
          phone: "Error fetching phone.",
          whatsappLink: "#",
          telegramLink: "#",
        });
      }
    };

    fetchContactInfo();
  }, []); // Empty dependency array to run only on mount

  return (
    <div className="vh-100">
      <div className="bgred">
        <div className="d-flex align-items-center px-2 pt-2">
        <Link to="/teer" className="noudl">
            <div className="me-2">
              <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
            </div>
          </Link>
          <div className="bbhg me-2">Help & Support</div>
        </div>
      </div>

      <div className="m-2 px-2">
        <div className="row">
          <div className="col py-3 text-start">
            <div className="wlt3">Email :</div>
          </div>
          <div className="col py-3 text-start">
            <div className="wlt3">{contactInfo.email}</div>
          </div>
        </div>

        <div className="row">
          <div className="col py-3 text-start">
            <div className="wlt3">Address :</div>
          </div>
          <div className="col py-3 text-start">
            <div className="wlt3">{contactInfo.address}</div>
          </div>
        </div>

        <div className="row">
          <div className="col py-3 text-start">
            <div className="wlt3">Phone :</div>
          </div>
          <div className="col py-3 text-start">
            <div className="wlt3">{contactInfo.phone}</div>
          </div>
        </div>

        <div className="row">
          <div className="col py-3 text-center">
            <div className="wlt3">Monday to Saturday (10 AM to 7 PM)</div>
          </div>
        </div>
      </div>

      <div className="px-3">
        <div className="row bgred1 mx-1 py-2">
          <div className="col-4 py-3 brt1">
            <div className="text-white">
              <a href={`tel:${contactInfo.phone}`} className="text-white lakas">
                <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-0" />
                <div className="text-white huj">Call Us</div>
              </a>
            </div>
          </div>

          <div className="col-4 py-3 brt1">
            <div className="text-white">
              <a href={contactInfo.whatsappLink} className="lakas" target="_blank" rel="noopener noreferrer">
                <img src={what} className="img-fluid imgh" alt="WhatsApp" />
                <div className="text-white huj">WhatsApp Us</div>
              </a>
            </div>
          </div>

          <div className="col-4 py-3">
            <div className="text-white">
              <a href={contactInfo.telegramLink} target="_blank" className="lakas" rel="noopener noreferrer">
                <img src={Telegram} className="img-fluid imgh" alt="Telegram" />
                <div className="text-white huj">Follow Us</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpSupport;
