import React, { useEffect, useState } from "react";
import axios from 'axios'; 
import Baseurl from '../Baseurl.js';

function ScrollComp() {
  // State to hold the fetched scroll text
  const [scrollText, setScrollText] = useState('');

  useEffect(() => {
    // Function to fetch the scroll text from the API
    const fetchScrollText = async () => {
      try {
        const response = await axios.post(`${Baseurl}/fetch-website-detail.php`);
        
        // Access the scroll_text from the first item in the data array
        if (response.data.data && response.data.data.length > 0) {
          setScrollText(response.data.data[0].scroll_text); // Update state with the fetched scroll text
        } else {
          setScrollText("No scroll text available."); // Fallback if no data is returned
        }
      } catch (error) {
        console.error("Error fetching scroll text:", error);
        setScrollText("Error fetching scroll text."); // Fallback for errors
      }
    };

    fetchScrollText();
  }, []);  

  return (
    <>
      <div className="scroll-text">
        <div className="scroll-text-content">
          {scrollText || "Loading..."} {/* Display the scroll text or a loading message */}
        </div>
      </div>
    </>
  );
}

export default ScrollComp;
