import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser, faPhone, faShare } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS

import Baseurl from '../Baseurl.js';

function Register() {
  const [formData, setFormData] = useState({
    username: '',
    name: '',
    mpin: '',
    mobileNumber: '',
    password: '',
    confirmPassword: '',
    referCode: '',
    is18Plus: false,
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate(); // Initialize useNavigate

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      toast.error('Passwords do not match'); // Show toast for password mismatch
      return;
    }

    if (!formData.is18Plus) {
      setError('You must confirm you are 18+');
      toast.error('You must confirm you are 18+'); // Show toast for age confirmation
      return;
    }

    setError(''); // Clear previous errors

    try {
      // Log the form data to the console before sending it
      console.log('Form Data:', formData);

      // Make API call to register user using axios
      const response = await axios.post(`${Baseurl}/register-user.php`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Log the entire response object to the console
      console.log('Response:', response);

      // Log the response text or data
      console.log('Response Data:', response.data);

      if (response.status === 200) {
        // Handle successful registration
        setSuccess('Registration successful! Redirecting to login...');
        toast.success('Registration successful! Redirecting to login...'); // Show toast for success
        
        // Redirect after 3 seconds
        setTimeout(() => {
          navigate('/Loginfirst'); // Navigate to login page
        }, 3000);
      } else {
        // Handle errors from API response
        setError(response.data.message || 'Registration failed');
        toast.error(response.data.message || 'Registration failed'); // Show toast for registration failure
      }
    } catch (err) {
      // Log error response if there's a failure
      if (err.response) {
        console.log('Error Response:', err.response);
        setError(err.response.data.message || 'An error occurred while registering. Please try again later.');
        toast.error(err.response.data.message || 'An error occurred while registering. Please try again later.'); // Show toast for error response
      } else {
        console.log('Error:', err.message);
        setError('An error occurred while registering. Please try again later.');
        toast.error('An error occurred while registering. Please try again later.'); // Show toast for generic error
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="bgred"></div>

        <div className="input-container">
          <FontAwesomeIcon icon={faUser} className="input-icon" />
          <input
            type="text"
            name="username"
            placeholder="User Name"
            className="login-input"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faUser} className="input-icon" />
          <input
            type="text"
            name="name"
            placeholder="Name"
            className="login-input"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faLock} className="input-icon" />
          <input
            type="text"
            name="mpin"
            placeholder="MPIN"
            className="login-input"
            value={formData.mpin}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faPhone} className="input-icon" />
          <input
            type="text"
            name="mobileNumber"
            placeholder="Mobile Number"
            className="login-input"
            value={formData.mobileNumber}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faLock} className="input-icon" />
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="login-input"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faLock} className="input-icon" />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            className="login-input"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faShare} className="input-icon" />
          <input
            type="text"
            name="referCode"
            placeholder="Refer Code"
            className="login-input"
            value={formData.referCode}
            onChange={handleChange}
          />
        </div>

        <div className="form-check input-container text-start mt-1 px-5">
          <input
            className="form-check-input"
            type="checkbox"
            name="is18Plus"
            checked={formData.is18Plus}
            onChange={handleChange}
            required
          />
          <label className="form-check-label text-danger">
            I Confirm that I am 18+ years in age
          </label>
        </div>

        <div>
          <button type="submit" className="btnn">Register</button>
        </div>

        {/* Display success or error messages */}
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {success && <p style={{ color: 'green' }}>{success}</p>}
      </form>
      <ToastContainer /> {/* Add the ToastContainer here */}
    </div>
  );
}

export default Register;
