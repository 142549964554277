import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faInr } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "../Component/Offcanvas";
import { Link } from "react-router-dom";
import Baseurl from '../Baseurl.js'; // replace with your actual API base URL
import { useParams } from "react-router-dom"; 

function Guti() {

  const { id } = useParams();

  const [inputValues, setInputValues] = useState(Array(100).fill(""));
  const [apiResponse, setApiResponse] = useState(null); // State to store API response
  const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const [showModal, setShowModal] = useState(false); // Modal visibility state

  // Function to handle input changes
  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (!value || /^[0-9]*$/.test(value)) {
      const values = [...inputValues];
      values[index] = value;
      setInputValues(values);
    }
  };

  // Prevent negative numbers
  const preventNegativeInput = (event) => {
    if (event.key === "-" || event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
  };

  // Calculate the total points
  const totalPoints = inputValues.reduce((sum, value) => {
    const num = parseFloat(value);
    return sum + (isNaN(num) ? 0 : num);
  }, 0);

  // Helper function to generate rows
  const renderRows = () => {
    const rows = [];
    for (let i = 0; i <= 99; i += 2) {
      rows.push(
        <tr key={i}>
          <td><strong>{i.toString().padStart(2, '0')}</strong></td>
          <td>
            <input
              type="number"
              className="form-control mxinp2"
              placeholder="Amount"
              value={inputValues[i]}
              onChange={(e) => handleInputChange(i, e)}
              onKeyDown={preventNegativeInput}
            />
          </td>
          <td><strong>{(i + 1).toString().padStart(2, '0')}</strong></td>
          <td>
            <input
              type="number"
              className="form-control mxinp2"
              placeholder="Amount"
              value={inputValues[i + 1]}
              onChange={(e) => handleInputChange(i + 1, e)}
              onKeyDown={preventNegativeInput}
            />
          </td>
        </tr>
      );
    }
    return rows;
  };

  // Fetch funds API after component mounts
  useEffect(() => {
    const fetchFunds = async () => {
      const userId = localStorage.getItem("userid"); // Get the user ID from localStorage
      if (userId) {
        try {
          setIsLoading(true); // Set loading to true before making the request
          const response = await axios.post(`${Baseurl}/add-funds.php`, {
            user_id: userId,
          });
          console.log("API Response:", response.data); // Log the response
          setApiResponse(response.data); // Store response in state
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false); // Set loading to false after the request completes
        }
      } else {
        console.log("No user ID found in localStorage");
      }
    };

    fetchFunds(); // Call the function to fetch data
  }, []); // Empty dependency array ensures it runs only once when the component mounts

  // Function to format current date as "dd/mm/yyyy Day"
  const getCurrentDate = () => {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, '0'); // Day
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month
    const year = today.getFullYear(); // Year

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayOfWeek = daysOfWeek[today.getDay()]; // Day of the week

    return `${day}/${month}/${year} ${dayOfWeek}`;
  };

  // Modal submit button handler
  const handleSubmit = async () => {
    const userId = localStorage.getItem("userid"); // Get the user ID from localStorage

    // Collect input values as an array of key-value pairs
    const inputData = inputValues.map((value, index) => {
      if (value) {
        return { inputKey: index, inputValue: value }; // Pair of input key and value
      }
      return null; // Skip inputs with empty values
    }).filter(item => item !== null); // Remove null values

    // Add totalPoints, userId, and gameId to the data
    const dataToSend = {
      inputs: inputData,
      totalPoints,
      userId,
      gameId: id, // Send the game ID obtained from the URL
    };

    console.log("Sent data:", dataToSend); // Log the data to check

    // Send the data to the API
    try {
      const response = await axios.post(`${Baseurl}/submit-gutti.php`, dataToSend);
      console.log("API Response:", response.data); // Log the API response
    } catch (error) {
      console.error("Error sending data:", error);
    }

    setShowModal(false); // Close the modal after submit
  };

  // Check if there are any input values filled
  const isSubmitDisabled = inputValues.every(value => value === "");

  return (
    <div className="vh-100">
      <Offcanvas />
      <div className="bgred">
        <div className="row px-3 py-2">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <Link to="/game">
                <FontAwesomeIcon icon={faArrowLeft} className="baricn" />
              </Link>{" "}
              <span className="text-white">
                <strong>House Board</strong>
              </span>
            </div>
            <div>
              <FontAwesomeIcon icon={faInr} className="baricn" />{" "}
              <span className="text-white">
                {isLoading ? (
                  <div className="spinner-border text-white" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <strong>{apiResponse ? apiResponse.balance : "0"}</strong>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="vh-90">
        <div className="mt-3">
          <div className="lo txt-red">GUTI</div>
          <div className="btnn4">{getCurrentDate()} BET RUNNING</div> {/* Display current date */}
        </div>

        <div className="row p-0 m-0 over">
          <table className="table text-center">
            <tbody>{renderRows()}</tbody>
          </table>
        </div>

        <div className="row p-0 m-0">
          <div className="col p-0 m-0">
            <div className="btnn4">Total Points: {totalPoints}</div>
          </div>
          <div className="col p-0 m-0">
            <button 
              type="button" 
              className="btnn4"
              onClick={() => setShowModal(true)} // Open modal on click
              disabled={isSubmitDisabled} // Disable the button if no input is filled
            >
              Submit
            </button>
          </div>
        </div>

        {/* Modal */}
        <div className={`modal fade ${showModal ? 'show' : ''}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ background: "#090909ba", display: showModal ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="laoqa11a">Choose Wallet</div>
                <div className="text-start mt-3">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked />
                    <label className="form-check-label radlabl" htmlFor="flexRadioDefault1">
                      Main Wallet : Rs {apiResponse ? apiResponse.balance : "Loading..."}
                    </label>
                  </div>
                </div> 
              </div>
              <div className="modal-center mb-2">
                <button type="button" className="btnsubmitgutti " onClick={handleSubmit}>SUBMIT</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guti;
