import React, { useState, useEffect } from "react";
import axios from "axios";
import Baseurl from '../Baseurl.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import what from "../Component/img/what.svg";
import Telegram from "../Component/img/Telegram.webp";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
function AddFund() {
  const [points, setPoints] = useState(""); // State for points entered by the user
  const [paymentMethod, setPaymentMethod] = useState(""); // State for payment method
  const [balance, setBalance] = useState(0); // State for balance, default is 0
  const userId = localStorage.getItem("userid"); // Get user ID from localStorage

  // Fetch the current balance when the component loads
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axios.post(`${Baseurl}/add-funds.php`, {
          user_id: userId
        });

        if (response.data.balance) {
          setBalance(response.data.balance); // Set the balance from API response
        } else {
          toast.error("Error fetching balance.");
        }
      } catch (error) {
        console.error("Error fetching balance:", error);
        toast.error("Error fetching balance.");
      }
    };

    fetchBalance(); // Call the fetch balance function when the component mounts
  }, [userId]);

  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value); // Update payment method based on radio selection
  };

  const handleSubmit = async () => {
    if (!points || !paymentMethod) {
      toast.error("Please enter points and select a payment method."); // Show error toast
      return;
    }

    try {
      const response = await axios.post(`${Baseurl}/add-funds.php`, {
        user_id: userId,
        amount: points,
        pay_mod: paymentMethod
      });

      if (response.data.message === "Payment added successfully.") {
        toast.success("Funds added successfully!");

        // Update the balance with the returned value
        if (response.data.balance) {
          setBalance(response.data.balance); // Set the balance from API response
        }
      } else {
        toast.error("Error: " + response.data.message); // Show error toast
      }
    } catch (error) {
      console.error("Error adding funds:", error);
      toast.error("Error while adding funds."); // Show error toast
    }
  };

  return (
    <div>
      <ToastContainer /> {/* Toast Container to display notifications */}
      <div className="bgred">
        <div className="d-flex align-items-center px-2 pt-2">
          
        <Link to="/teer" className="noudl"> 
          <div className="me-2">
            <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
          </div>
          </Link>
          <div className="bbhg me-2">Add Funds</div>
        </div>
      </div>
      <div className="m-2 bd2">
        <div className="bldblk1">ADD FUNDS</div>
        <div className="bldblk2 laoi">
          Add the money given below and you will get 5% cashback.
        </div>

        <div className="row mt-3">
          <div className="col">
            <div className="bgwhatsapp">
              <span>
                <img src={what} className="img-fluid imgh" />
              </span>
              <span className="text-white huj">Whatsapp</span>
            </div>
          </div>

          <div className="col">
            <div className="bgtele">
              <span>
                <img src={Telegram} className="img-fluid imgh" />
              </span>
              <span className="text-white huj">Telegram</span>
            </div>
          </div>
        </div>
      </div>

      <div className="px-3">
        <div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-wallet2 icnn2"
              viewBox="0 0 16 16"
            >
              <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z"></path>
            </svg>
          </span>
          <span className="wlt2 px-2">{balance}</span> {/* Display updated balance */}
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faMoneyBillAlt} className="input-icon" />
          <input
            type="text"
            placeholder="Enter Points"
            className="login-input"
            value={points}
            onChange={(e) => setPoints(e.target.value)} // Update points value
          />
        </div>

        <div className="mt-3 mx-3 hygf p-2">
          <div className="form-check text-start">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMethod"
              value="google_pay"
              onChange={handlePaymentChange}
            />
            <label className="form-check-label asju" htmlFor="flexRadioDefault1">
              Google Pay
            </label>
          </div>

          <div className="form-check text-start">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMethod"
              value="other"
              onChange={handlePaymentChange}
            />
            <label className="form-check-label asju" htmlFor="flexRadioDefault1">
              Others
            </label>
          </div>
        </div>

        <div className="row fixed-footer2 justify-content-center d-flex">
          <div className="col-12">
            <button className="btnn3" onClick={handleSubmit}>
              ADD REQUEST
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddFund;
