import React, { useEffect, useState } from "react";
import logo from "../Component/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faBars, faStop } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "../Component/Offcanvas";
import ScrollComp from "../Component/ScrollComp";
import { useNavigate, Link } from 'react-router-dom'; 
import axios from 'axios'; 
import Baseurl from '../Baseurl.js'; 

function Teer() {
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const [markets, setMarkets] = useState([]);
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    if (!userId) {
      navigate('/');
    } else {
      const fetchMarkets = async () => {
        try {
          const response = await axios.post(`${Baseurl}/fetch-all-market.php`);
          setMarkets(response.data.data);
        } catch (error) {
          console.error("Error fetching markets:", error);
        }
      };
      fetchMarkets();
    }
  }, [userId, navigate]);

  useEffect(() => {
    const formatCurrentDate = () => {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };
    setCurrentDate(formatCurrentDate());
  }, []);

  const convertTimeTo24HourFormat = (time) => {
    const [hourMinute, modifier] = time.split(' ');
    const [hour, minute] = hourMinute.split(':').map(Number);
    let hoursIn24Format = hour % 12;
    if (modifier === 'PM') {
      hoursIn24Format += 12;
    }
    return [hoursIn24Format, minute];
  };

  const isMarketOpen = (openTime, closeTime) => {
    const now = new Date();
    const [openHour, openMinute] = convertTimeTo24HourFormat(openTime);
    const [closeHour, closeMinute] = convertTimeTo24HourFormat(closeTime);

    const openDate = new Date(now);
    openDate.setHours(openHour, openMinute, 0, 0);

    const closeDate = new Date(now);
    closeDate.setHours(closeHour, closeMinute, 0, 0);

    return now >= openDate && now <= closeDate;
  };

  return (
    <div className=" ">
      <Offcanvas />
      <div className="bgred ">
        <div className="row">
          <div className="col-2">
            <FontAwesomeIcon
              icon={faBars}
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
              className="baricn mt-2"
            />
          </div>
        </div>
      </div>
      <ScrollComp/>
      <div className="card m-2 bd2">
        Our Application Licence number is '1927'. We are happy to see you
        winning with us. Disclaimer: This game may be habit-forming or
        financially risky. Play responsibly.
      </div>
      <div>
        <button className="btnn2">{currentDate}</button>
      </div>
      <div className="mnj">
        {markets.map((market, index) => {
          const marketContent = (
            <div className="card crd1 no-underline" key={index}>
              <div className="row">
                <div className="col-3">
                  <img src={logo} className="img-fluid ft2" alt="Logo" />
                </div>
                <div className="col-6 align-content-center">
                  <div className="lo">{market.game_name}</div>
                </div>
                <div className="col-3 align-content-center justify-content-center d-flex mt-3">
                  <div className="playfa align-content-center">
                    {isMarketOpen(market.open_time, market.close_time) ? (
                      <FontAwesomeIcon icon={faPlay} className="text-white" />
                    ) : (
                      <FontAwesomeIcon icon={faStop} />
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3">
                  <div className="lpo1">Open {market.open_time}</div>
                </div>
                <div className="col-6 align-content-center">
                  {isMarketOpen(market.open_time, market.close_time) ? (
                    <div className="lo txt-grn">BID IS RUNNING</div>
                  ) : (
                    <div className="lo txt-rde">BID IS CLOSED</div>
                  )}
                </div>
                <div className="col-3 align-content-center">
                  <div className="lpo1">Close {market.close_time}</div>
                </div>
              </div>
            </div>
          );

          // Wrap in a link with market.id as a dynamic parameter in the URL.
          return isMarketOpen(market.open_time, market.close_time) ? (
            <Link to={`/game/${market.id}`} className="no-underline" key={index}>
              {marketContent}
            </Link>
          ) : (
            <div key={index}>{marketContent}</div>
          );
        })}
      </div>
      <div className="row fixed-footer justify-content-center d-flex">
        <div className="col-6">
          <Link to="/addfund" className="btnn3 no-underline">ADD FUNDS</Link>
        </div>
        <div className="col-6">
          <button className="btnn3">WITHDRAW FUNDS</button>
        </div>
      </div>
    </div>
  );
}

export default Teer;
