import React from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faPlus, faHistory, faChartLine, faSignOutAlt, faQuestionCircle, faCoins, faMoneyBillWave, faHandshake, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Offcanvas() {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    marginRight: "10px",
  };

  const textStyle = {
    color: "white",
    textAlign: "start",
  };

  return (
    <>
      {/* Offcanvas component from top */}
      <div
        className="offcanvas offcanvas-top cnva vh-100"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header back1">
          <div style={containerStyle}>
            <div style={iconStyle}>
              <FontAwesomeIcon icon={faUser} size="2x" className="text-white" />
            </div>
            <div style={textStyle}>
              <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                Sahu
              </h5>
              <p>9999999999</p>
            </div>
          </div>

          <button
            type="button"
            className="btn-close text-reset text-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <Link to="/teer" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faHome} size="2x" className="text-dark  sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Home</div>
              </div>
            </div>
          </Link>

          <Link to="/profile" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faUser} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Profile</div>
              </div>
            </div>
          </Link>

          <Link to="/addbank" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faPlus} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Add Bank Account</div>
              </div>
            </div>
          </Link>

          <Link to="/gamehistory" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faHistory} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Game History</div>
              </div>
            </div>
          </Link>

          <Link to="/pointhistory" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faChartLine} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Points History</div>
              </div>
            </div>
          </Link>

          <Link to="/referhistory" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faHandshake} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Refer History</div>
              </div>
            </div>
          </Link>

          <Link to="/friendtransfer" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faHandshake} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Friends Transfer History</div>
              </div>
            </div>
          </Link>

          <Link to="/fcbidhistory" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faCoins} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">FC Bid History</div>
              </div>
            </div>
          </Link>

          <Link to="/bcbidhistory" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faCoins} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">BS Bid History</div>
              </div>
            </div>
          </Link>

          <Link to="/addfund" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faPlus} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Add Points</div>
              </div>
            </div>
          </Link>

          <Link to="/withdraw" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faMoneyBillWave} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Withdraw Points</div>
              </div>
            </div>
          </Link>

          <Link to="/howtoplay" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faQuestionCircle} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">How To Play</div>
              </div>
            </div>
          </Link>

          <Link to="/gamerate" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faChartLine} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Game Rates</div>
              </div>
            </div>
          </Link>

          <Link to="/sendmoney" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faMoneyBillWave} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Send Money to Friend</div>
              </div>
            </div>
          </Link>

          <Link to="/help" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faEnvelope} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Help</div>
              </div>
            </div>
          </Link>

          <Link to="/" className="noudl">
            <div className="row mb-3">
              <div className="col-3">
                <FontAwesomeIcon icon={faSignOutAlt} size="2x" className="text-dark sideicon" />
              </div>
              <div className="col-9 m-0 p-0">
                <div className="men1 text-start">Logout</div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Offcanvas;
