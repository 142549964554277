import React from "react";
import { useParams } from "react-router-dom";  // Import useParams to get URL params
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Game() {
  const { id } = useParams();  // Get the id parameter from the URL

  return (
    <div className="vh-100">
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
          <Link to="/teer" className="noudl">
            <div className="me-2">
              <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
            </div>
          </Link>
          <div className="bbhg me-2">KHANAPARA NIGHT TEER FR DASHBOARD</div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <Link to={`/guti/${id}`} className="hsj">
          <div className="kih align-content-center">Guti Play</div>
        </Link>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <div className="kih align-content-center">
          Housing <br /> & <br /> Ending
        </div>
      </div>

      
    </div>
  );
}

export default Game;
