import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation and useNavigate
import axios from 'axios'; // Import axios for API calls
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'; 
import logo from '../Component/img/logo.png';
import Baseurl from '../Baseurl.js';

function Otp() {
    const location = useLocation();
    const navigate = useNavigate(); // Initialize the navigate function
    const queryParams = new URLSearchParams(location.search);
    const phoneNumberFromUrl = queryParams.get('phoneNumber'); // Get phone number from URL
    const [phoneNumber, setPhoneNumber] = useState(phoneNumberFromUrl || ''); // Set phone number state
    const [otp, setOtp] = useState(Array(6).fill('')); // State to store each OTP digit

    // Handle OTP input change
    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value.replace(/\D/, ''); // Allow only digits
        setOtp(newOtp);

        // Move to the next input when a digit is entered
        if (value && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }

        // Move to the previous input when backspace is pressed
        if (!value && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const otpString = otp.join('');
        
        // Call the API to verify the OTP
        try {
            const response = await axios.post(`${Baseurl}/verify-otp.php`, {
                phoneNumber,
                otp: otpString,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.data.success) {
                // Store user ID in local storage
                localStorage.setItem('userId', response.data.userId);

                // Redirect to the desired page (e.g., dashboard)
                navigate('/teer'); // Replace '/dashboard' with your desired route
                console.log('OTP verified successfully:', response.data);
            } else {
                console.error('OTP verification failed:', response.data.message);
            }
        } catch (error) {
            console.error('Error verifying OTP:', error.response?.data?.message || error.message);
        }
    };

    return (
        <div className="otp-container">
            <div className="bgred"></div>
            <div className="mt-3">
                <img src={logo} className="img-fluid ft1" alt="Logo" />
            </div>

            <div className="input-container">
                <FontAwesomeIcon icon={faUser} className="input-icon" />
                <input
                    type="text"
                    placeholder="Phone Number"
                    className="login-input"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                    readOnly // Make it read-only as we get it from the URL
                />
            </div>

            <div className="otp-input-container">
                {otp.map((digit, index) => (
                    <input
                        key={index}
                        type="text"
                        id={`otp-input-${index}`}
                        value={digit}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        maxLength={1}
                        className="otp-input"
                    />
                ))}
            </div>

            <div>
                <button className="btnn" onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
}

export default Otp;
