import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications
import Baseurl from "../Baseurl.js"; // Adjust the path as necessary

function ProfileComp() {
  // State variables for profile details
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [error, setError] = useState(null); // To store error messages

  // Fetch profile data when component mounts
  useEffect(() => {
    const fetchProfileData = async () => {
      const userId = localStorage.getItem("userid"); // Get user ID from local storage

      // Log userId for debugging
      console.log('User ID:', userId);

      if (!userId) {
        setError('User ID not found.');
        return;
      }

      try {
        const response = await axios.get(`${Baseurl}get-user.php`, {
          params: {
            user_id: userId,
          },
        });

        // Log the response from the API
        console.log('API Response for fetchProfileData:', response.data);

        if (response.data.error) {
          setError(response.data.error);
        } else {
          // Set existing profile data
          setMobile(response.data.phone);
          setEmail(response.data.email);
          setDob(response.data.dob);
        }
      } catch (err) {
        setError('Network error. Please try again later.');
      }
    };

    fetchProfileData();
  }, []);

  // Validate mobile number (10 digit numeric)
  const validateMobile = (mobile) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };

  // Validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handler for updating profile
  const handleProfileUpdate = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const userId = localStorage.getItem("userid"); // Get user ID from local storage
    console.log('User ID during update:', userId); // Log user ID for debugging

    if (!userId) {
      setError('User ID not found.');
      return;
    }

    // Validate input
    if (!mobile || !email || !dob) {
      setError('All fields in the profile are required.');
      return;
    }

    if (!validateMobile(mobile)) {
      setError('Mobile number must be 10 digits.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Invalid email format.');
      return;
    }

    try {
      // Log the data being sent to the API
      const requestData = {
        user_id: userId,
        mobile,
        email,
        dob,
      };
      console.log('Data sent to update-profile API:', requestData);

      // Send API request to update profile using Axios
      const response = await axios.get(`${Baseurl}update-profile.php`, {
        params: requestData,
      });

      // Log the response from the API
      console.log('API Response for updateProfile:', response.data);

      // Handle success
      if (response.data.error) {
        setError(response.data.error); // Set error from response
      } else {
        toast.success(response.data.message || 'Profile updated successfully!'); // Show success toast
        setError(null); // Clear any previous errors
      }
    } catch (err) {
      // Handle error
      if (err.response) {
        setError(err.response.data.message || 'An error occurred while updating the profile.');
      } else {
        setError('Network error. Please try again later.');
      }
    }
  };

  return (
    <div className="m-2 bg-white bdrs">
      <form onSubmit={handleProfileUpdate}>
        <div className="row m-0 p-0 g-3">
          <div className="col">
            <div className="py-2 px-3">
              <div className="ty3 lo">Profile</div>

              <div className="input-container2">
                <div className="text-start">
                  <label className="lbl12">Mobile</label>
                </div>
                <input
                  type="text"
                  placeholder="Mobile Number"
                  className="login-input2"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  maxLength={10} // Limit input length to 10
                />
              </div>

              <div className="input-container2">
                <div className="text-start">
                  <label className="lbl12">Email</label>
                </div>
                <input
                  type="text"
                  placeholder="Email"
                  className="login-input2"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="input-container2">
                <div className="text-start">
                  <label className="lbl12">Date Of Birth</label>
                </div>
                <input
                  type="date"
                  className="login-input2"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <button type="submit" className="btnn mb-3">Update</button>
        </div>
      </form>
      {error && <div className="text-danger">{error}</div>}
      <ToastContainer /> {/* Add ToastContainer here */}
    </div>
  );
}

export default ProfileComp;
