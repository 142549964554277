import React, { useEffect, useState } from "react";
import axios from "axios";
import Baseurl from "../Baseurl.js";
import logo from "../Component/img/logo.png";  
import Offcanvas from "../Component/Offcanvas";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'; 
import { Link } from "react-router-dom";

function GameRate() {
  const [gameRates, setGameRates] = useState([]); // State to store game rates
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchGameRates = async () => {
      try {
        const response = await axios.post(`${Baseurl}/fetch-all-game-rates.php`);
        setGameRates(response.data.data); // Set the fetched game rates
      } catch (err) {
        setError('Error fetching game rates'); // Set error message if fetching fails
      } finally {
        setLoading(false); // Set loading to false after the fetch is complete
      }
    };

    fetchGameRates(); // Call the function to fetch game rates
  }, []); // Empty dependency array to run the effect only once

  return (
    <div className="vh-100">
      <div className="bgred ">
        <div className="d-flex align-items-center px-2 pt-2">
        <Link to="/teer" className="noudl">
            <div className="me-2">
              <FontAwesomeIcon icon={faArrowLeft} className="baricn mt-2" />
            </div>
          </Link>
          <div className="bbhg me-2">Game Rates</div>
        </div>
      </div>

      <div className="m-2 ">
        <div className="row">
          <div className="col py-3">
            <div className="text-center rate1">GAME RATES</div>

            {loading ? (
              <div className="text-center">Loading...</div> // Loading state
            ) : error ? (
              <div className="text-center text-danger">{error}</div> // Error state
            ) : (
              <table className="table table-bordered tbl1 tbl2 mb-1 mt-1">
                <tbody>
                  {gameRates.map((rate) => (
                    <tr key={rate.rate_id} className="">
                      <td className="text-start py-2 pop1">{rate.game_name}</td>
                      <td className="text-start py-2 pop2">{rate.amt1} KA {rate.amt2}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <div className="text-center rate2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameRate;
