import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for API calls
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'; 
import logo from '../Component/img/logo.png';
import Baseurl from '../Baseurl.js';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { toast, ToastContainer } from 'react-toastify'; // Import Toast for notifications
import 'react-toastify/dist/ReactToastify.css'; 

function Login2() {
  const [showLogo, setShowLogo] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const [error, setError] = useState('');
  
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  // Handle form submission for regular login
  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate phone number format
    const phoneRegex = /^[0-9]{10}$/; // Example regex for 10 digit phone number
    if (!phoneRegex.test(phoneNumber)) {
      toast.error('Please enter a valid 10-digit phone number.', { position: "top-center" });
      return;
    }

    try {
      // Make API call for regular login
      const response = await axios.post(`${Baseurl}/login.php`, {
        phoneNumber,
        password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Handle successful login
      if (response.status === 200 && response.data.success) {
        // Store userid in localStorage
        localStorage.setItem('userid', response.data.userid);

        // Log the current contents of localStorage
        console.log('Current localStorage:', localStorage);

        console.log('Login successful:', response.data);
        
        // Show success toast message
        toast.success('Login successful!', { position: "top-center", autoClose: 2000 });

        // Wait for 2 seconds before redirecting
        setTimeout(() => {
          navigate('/teer');
        }, 2000);
        
      } else {
        toast.error(response.data.message || 'Login failed', { position: "top-center" });
      }
    } catch (err) {
      toast.error(err.response?.data?.message || 'An error occurred while logging in. Please try again later.', { position: "top-center" });
    }
  };

  // Handle form submission for login with OTP
  const handleLoginWithOTP = async (e) => {
    e.preventDefault();

    // Validate phone number format
    const phoneRegex = /^[0-9]{10}$/; // Example regex for 10 digit phone number
    if (!phoneRegex.test(phoneNumber)) {
        toast.error('Please enter a valid 10-digit phone number.', { position: "top-center" });
        return;
    }

    try {
        // Make API call for login with OTP
        const response = await axios.post(`${Baseurl}/login-with-otp.php`, {
            phoneNumber,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Handle successful OTP login
        if (response.status === 200) {
            // Redirect to OTP page with phone number in the URL
            navigate(`/otp?phoneNumber=${encodeURIComponent(phoneNumber)}`);
            console.log('OTP login initiated:', response.data);
        } else {
            toast.error(response.data.message || 'OTP login failed', { position: "top-center" });
        }
    } catch (err) {
        toast.error(err.response?.data?.message || 'An error occurred while logging in with OTP. Please try again later.', { position: "top-center" });
    }
};

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <ToastContainer /> {/* Toast Container for notifications */}

      {showLogo && (
        <div className="bg-redd2 align-items-center">
          <div className="mt-3">
            <img src={logo} className="img-fluid ft1" alt="Logo" />
          </div>
        </div>
      )}

      <div className="bgred"></div>
      <div className="mt-3">
        <img src={logo} className="img-fluid ft1" alt="Logo" />
      </div>

      <form onSubmit={handleLogin}>
        <div className="input-container">
          <FontAwesomeIcon icon={faUser} className="input-icon" />
          <input
            type="text"
            placeholder="Phone Number"
            className="login-input"
            value={phoneNumber}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 10 && /^[0-9]*$/.test(value)) { // Validate for up to 10 digits and only numbers
                setPhoneNumber(value);
              }
            }}
            required
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault(); // Prevent non-numeric input
              }
            }}
          />
        </div>

        <div className="input-container">
          <FontAwesomeIcon icon={faLock} className="input-icon" />
          <input
            type={showPassword ? 'text' : 'password'} // Show password based on state
            placeholder="Password"
            className="login-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <div className="form-check input-container text-start mt-1 px-5">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            checked={showPassword}
            onChange={togglePasswordVisibility}
          />
          <label className="form-check-label text-danger" htmlFor="flexCheckDefault">
            Show Password
          </label>
        </div>

        <div>
          <button type="submit" className="btnn">Login</button>
        </div>
      </form>

      <div>
        <button onClick={handleLoginWithOTP} className="btnn">Login With OTP</button>
      </div>

      <div className="row">
        <div className='col'>
          <div className="fg1 text-center mx-3 mt-2">FORGET PASSWORD ?</div>
        </div>
        <div className='col'>
          <div className="fg1 text-center mx-3 mt-2">
            <a href="/register" style={{ textDecoration: 'none', color: 'inherit' }}>NEW USER ?</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login2;
